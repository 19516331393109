import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState, lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux';
import { setUserDataAfterLogin, localstorage_TokenAdd, setUserType } from "./Store/Reducers/CommonReducer";
// js
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import ScrollToTop from "./component/ScrollToTop.js";
/*import Layout from './pages/Layout/Layout';
import Home from "./pages/Home/Home"
import About from './pages/About/About';
import MyAccount from './pages/MyAccount/MyAccount';
import TermsCondition from './pages/TermsCondition/TermsCondition';
import RefundPolicy from './pages/RefundPolicy/RefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Contact from './pages/Contact/Contact';*/

import GridLoader from "react-spinners/GridLoader";

const Layout =  lazy(() => import('./pages/Layout/Layout'));
const Home =  lazy(() => import("./pages/Home/Home"));
const About =  lazy(() => import('./pages/About/About'));
const MyAccount =  lazy(() => import('./pages/MyAccount/MyAccount'));
const TermsCondition =  lazy(() => import('./pages/TermsCondition/TermsCondition'));
const RefundPolicy =  lazy(() => import('./pages/RefundPolicy/RefundPolicy'));
const PrivacyPolicy =  lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const Contact =  lazy(() => import('./pages/Contact/Contact'));
const SubSuccess = lazy(() => import('./pages/SubSuccess/SubSuccess'));
const SubProcesses = lazy(() => import('./pages/SubProcesses/SubProcesses'));
const SubFail = lazy(() => import('./pages/SubFail/SubFail'));


function App() {
  const dispatch = useDispatch();
  const {userType, userData} = useSelector(state => state.common);
  let [color, setColor] = useState("#2CBFEF");
  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const override = CSSProperties;

  useEffect(() => {
      if(sessionStorage.getItem("userId") != null){
        dispatch(setUserDataAfterLogin(JSON.parse(sessionStorage.getItem("userData"))));
        dispatch(localstorage_TokenAdd(sessionStorage.getItem("userToken")));
      }
      
  	}, []);

  return (
    <BrowserRouter >
        <ScrollToTop>
        <Suspense fallback={
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
              </div>   
            }>
          <Routes >
            
              <Route path="/" element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="about-us" element={<About />} />
                  <Route path="myaccount" element={<MyAccount />} />
                  <Route path="tearmcondition" element={<TermsCondition />} />
                  <Route path="refundpolicy" element={<RefundPolicy />} />
                  <Route path="privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="contact-us" element={<Contact />} />
                  <Route path="subscription-success" element={<SubSuccess />} />
                  <Route path="sub-processes" element={<SubProcesses />} />
                  <Route path="subscription-fail" element={<SubFail />} />
              </Route> 
              
          </Routes> 
          </Suspense>  
        </ScrollToTop>
    </BrowserRouter> 
  );
}

export default App;
